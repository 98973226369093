/*
 * @Description: 业务建模详情页面入口 openType:1新增 2查看 3只读,
 * {pageType}:1默认不全屏,能选择全屏或收起 2默认全屏,能关闭 3打开新页签,全屏 4弹窗类型
 * @Author: 梁平贤
 * @Date: 2020/7/8 15:24
 */

/**
 * 业务建模相关参数说明
 * @param path 路由地址,可以不传,默认会取 DEFAULTS_BUSINESS_ROUTE_PATH, 弹窗用不到此功能
 * @param openType 1新增 2查看 3只读 4编辑 (外部基本用不到,内部每种功能提供出去已经定义好了)
 * @param pageType 1默认不全屏,能选择全屏或收起 2默认全屏,能关闭 3打开新页签,全屏 4弹窗类型 (外部基本用不到,内部每种功能提供出去已经定义好了), 10默认全屏, 能选择全屏或收起
 * @param id 业务建模数据id,有则传,没有则不传
 * @param templateId 业务建模模板id
 * @param businessType 业务类型  具体查阅BusinessType枚举
 * @param objectType 基础数据类型
 * @param objectTypeId 基础数据类型id
 * @param templateName 模板名称
 * @param tripId 关联行程id
 * @param tripType 关联行程类型 报销单1,申请单2
 * @param attRecordId 关联考勤id
 * @param viewType 新增参数 3表示任务 5表示资源 因为都用同一个
 * @param sourcetype message:消息的需要处理一下额外参数  list开头:列表因为中间页面直接跳详情,临时页面,用来处理特殊的返回逻辑,有可能需要返回到列表的各种视图下
 */

import { cloneDeep } from "lodash";
import { BusinessDataOpenType, BusinessDataPageType } from "@/components/businessDetail/data/BusinessCommonHeader";
// 默认的业务建模路由地址
const DEFAULTS_BUSINESS_ROUTE_PATH = "/businessModel/detail";

/**
 * 返回弹窗新增业务建模所需参数 一般来说填充至 businessParams
 * @param templateId 模板id
 * @param businessType 业务类型
 * @param templateName 模板名称
 * @param attRecordId 考勤id
 * @param tripId 行程id
 * @param tripType 行程类型,报销单1,申请单2
 * @returns {{openType: number, pageType: number, templateId: *, businessType: *, templateName: *}}
 */
const newBusinessDialogParams = function newBusinessDialogParams({
                                                                   templateId, businessType, templateName, attRecordId, tripId, tripType
                                                                 }) {
  return {
    openType: BusinessDataOpenType.add,
    pageType: BusinessDataPageType.dialog,
    templateId,
    businessType,
    templateName,
    attRecordId,
    tripId,
    tripType
  };
};
/**
 * 返回弹窗新增业务建模所需参数 一般来说填充至 businessParams
 * @param templateId 模板id
 * @param businessType 业务类型
 * @param templateName 模板名称
 * @param attRecordId 考勤id
 * @param tripId 行程id
 * @param tripType 行程类型,报销单1,申请单2
 * @param taskSource 任务来源,沟通2,业务数据3
 * @returns {{openType: number, pageType: number, templateId: *, businessType: *,}}
 */
const newBusinessTaskParams = function newBusinessTaskParams({
  templateId, businessType, attRecordId, tripId, tripType, taskSourceId, taskSourceTemplateId, taskSource
}) {
  return {
    templateId,
    businessType,
    attRecordId,
    tripId,
    tripType,
    taskSourceId,
    taskSourceTemplateId,
    taskSource
  };
};

/**
 * 返回弹窗查看业务建模所需参数 一般来说填充至 businessParams
 * @param templateId 模板id
 * @param businessType 业务类型
 * @param originOpenBusinessType 弹窗打开的业务类型
 * @param templateName 模板名称
 * @param id 数据id
 * @param attRecordId 考勤id
 * @param tripId 行程id
 * @param tripType 行程类型,报销单1,申请单2
 * @returns {{openType: number, pageType: number, templateId: *, businessType: *, templateName: *}}
 */
const viewBusinessDialogParams = function viewBusinessDialogParams({
                                                                     templateId, businessType, templateName, id, attRecordId, tripId, tripType, originOpenBusinessType
                                                                   }) {
  return {
    openType: BusinessDataOpenType.view,
    pageType: BusinessDataPageType.dialog,
    templateId,
    businessType,
    templateName,
    id,
    attRecordId,
    tripId,
    tripType,
    originOpenBusinessType
  };
};

// 只读弹窗路由
const readonlyBusinessDialogParams = function readonlyBusinessDialogParams({
                                                                             templateId, businessType, templateName, id, attRecordId, tripId, tripType
                                                                           }) {
  return {
    openType: BusinessDataOpenType.readOnly,
    pageType: BusinessDataPageType.dialog,
    templateId,
    businessType,
    templateName,
    id,
    attRecordId,
    tripId,
    tripType
  };
};

/**
 * 业务建模新增路由
 * @param path 可以不填
 * @param templateId 模板id
 * @param businessType 业务类型 默认是2
 * @param templateName 模板名称
 * @param tripId 关联的行程id
 * @param tripType 关联的行程类型 1报销 2申请
 * @param picType 新增参数 1 列表视图 2 看板视图
 * @param viewType 新增参数  左侧下拉会显示用 1 列表视图 2 看板视图 3层级 4日历 5甘特
 * @returns {{path: (*|string), query: {templateId: *, openType: number, pageType: number, businessType: *, templateName: *}}}
 */
const newEntranceRoute = function newEntranceRoute({
                                                     path, templateId, businessType = 2, templateName, tripId, tripType, sourcetype, picType, viewType
                                                   }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  return {
    path: businessPath,
    query: {
      templateId,
      openType: BusinessDataOpenType.add,
      pageType: BusinessDataPageType.normal,
      businessType,
      templateName,
      sourcetype,
      tripId,
      tripType,
      picType,
      viewType
    }
  };
};
/**
 * 消息中心新增业务建模(按钮)
 * @param path
 * @param sourcetype 方便区分是消息中心新增来的
 * @param templateId
 * @param businessType
 * @param templateName
 * @param operateId
 * @param srcDataId
 * @param srcTemplateId
 * @param destTemplateId
 * @param destType
 * @param srcType

 */
const messageNewButtonEntranceRoute = function messageNewButtonEntranceRoute({
                                                                               path, templateId, businessType = 2, templateName, operateId, srcDataId, srcTemplateId, destTemplateId, destType, srcType
                                                                             }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  return {
    path: businessPath,
    query: {
      templateId,
      openType: BusinessDataOpenType.add,
      pageType: BusinessDataPageType.normal,
      sourcetype: "message",
      businessType,
      templateName,
      operateId,
      srcDataId,
      srcTemplateId,
      destTemplateId,
      destType,
      srcType
    }
  };
};
// 资源新增
// * @param picType 新增参数 1 列表视图 2 看板视图
// * @param viewType 新增参数  左侧下拉会显示用 1 列表视图 2 看板视图 3层级 4日历 5甘特
const resourceNewButtonEntranceRoute = function resourceNewButtonEntranceRoute({
                                                                               path, templateId, businessType = 2, templateName, resourceId, resourceName, resourceDate, resourceTime, picType, viewType
                                                                             }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  return {
    path: businessPath,
    query: {
      templateId,
      openType: BusinessDataOpenType.add,
      pageType: BusinessDataPageType.normal,
      sourcetype: "list_resources",
      businessType,
      templateName,
      resourceId,
      resourceName,
      resourceDate,
      resourceTime,
      picType,
      viewType
    }
  };
};

/**
 * 业务建模列表数据点击编辑/查看等
 * @param path
 * @param id
 * @param templateId
 * @param businessType
 * @param objectType
 * @param objectTypeId
 * @param templateName
 * @param tripId 关联的行程id
 * @param tripType 关联的行程类型 1报销 2申请
 * @returns {{path: (*|string), query: {id: *, templateId: *, openType: number, pageType: number, businessType: *, objectType: *, objectTypeId: *, templateName: *}}}
 */
const businessListEntranceRoute = function businessListEntranceRoute({
    path, id, templateId, businessType, objectType, objectTypeId, templateName, tripId, tripType, copyId, pageSwitchData, sourcetype = "list_normal", openType
  }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  if (pageSwitchData) {
    sessionStorage.setItem("businessPageSwitchData", JSON.stringify(pageSwitchData));
  }
  return {
    path: businessPath,
    query: {
      id,
      templateId,
      sourcetype,
      openType: openType || BusinessDataOpenType.view,
      pageType: BusinessDataPageType.normal,
      businessType,
      objectType,
      objectTypeId,
      templateName,
      tripId,
      tripType,
      copyId
    }
  };
};

// 业务建模详情页面左右切换路由
const businessDetailPageSwitchRoute = function businessDetailPageSwitchRoute({
                                                                       path, id, templateId, businessType, objectType, objectTypeId, templateName, openType, pageType, sourcetype
                                                                     }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  return {
    path: businessPath,
    query: {
      id,
      templateId,
      openType,
      pageType,
      businessType,
      objectType,
      objectTypeId,
      templateName,
      sourcetype
    }
  };
};

/**
 * 报表/看板穿透 直接全屏
 * @param templateId
 */
const readOnlyListEntranceRoute = function readOnlyListEntranceRoute({
                                                                       path, id, templateId, businessType, templateName
                                                                     }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  return {
    path: businessPath,
    query: {
      id,
      templateId,
      openType: BusinessDataOpenType.readOnly,
      pageType: BusinessDataPageType.fullscreen,
      businessType,
      templateName
    }
  };
};

/**
 * 新开页签查看数据,只读打开业务建模or基础数据,路由不需要push,直接会处理新开页签
 * @param id
 * @param templateId
 * @param businessType
 */
const newTabReadonlyBusinessData = function newTabReadonlyBusinessData(params) {
  // 参考以下注释传参 selectKey是识别单选的key
  // const {path, id, templateId, businessType, objectTypeId, objectType, selectKey } = params
  const businessPath = params.path || DEFAULTS_BUSINESS_ROUTE_PATH;
  let newUrl = `${window.location.origin}${businessPath}?openType=3&pageType=3`;
  Object.keys(params)
    .forEach((val) => {
      const value = params[val];
      if (value) {
        newUrl += `&${val}=${value}`;
      }
    });
  window.open(newUrl, "_blank");
};

/**
 * 新开页签查看数据,新开页签能否编辑是根据权限来的,打开业务建模or基础数据,路由不需要push,直接会处理新开页签
 * @param id
 * @param templateId
 * @param businessType
 */
const newTabViewBusinessData = function newTabViewBusinessData(params) {
  // 参考以下注释传参 selectKey是识别单选的key
  // const {path, id, templateId, businessType, objectTypeId, objectType, selectKey } = params
  const businessPath = params.path || DEFAULTS_BUSINESS_ROUTE_PATH;
  let newUrl = `${window.location.origin}${businessPath}?openType=2&pageType=3`;
  Object.keys(params)
    .forEach((val) => {
      const value = params[val];
      if (value) {
        newUrl += `&${val}=${value}`;
      }
    });
  window.open(newUrl, "_blank");
};

/**
 * 新开页签新增数据,路由不需要push,直接会处理新开页签
 * @param id
 * @param templateId
 * @param businessType
 */
const newTabAddBusinessData = function newTabAddBusinessData(params, openType = 1) {
  // 参考以下注释传参
  // const {path, id, templateId, businessType, objectTypeId, objectType } = params
  const businessPath = params.path || DEFAULTS_BUSINESS_ROUTE_PATH;
  let newUrl = `${window.location.origin}${businessPath}?openType=${openType}&pageType=3`;
  Object.keys(params)
    .forEach((val) => {
      const value = params[val];
      if (value) {
        newUrl += `&${val}=${value}`;
      }
    });
  window.open(newUrl, "_blank");
};

/**
 * 其他的页面穿透 e.g.待办/已办/我的业务/消息中心/任务/订单等等 , 根据权限来判断是否可以编辑
 * @param templateId
 */
const otherEntranceRoute = function otherEntranceRoute({
                                                         path, id, templateId, businessType, templateName, tripId, tripType, pageSwitchData
                                                       }) {
  const businessPath = path || DEFAULTS_BUSINESS_ROUTE_PATH;
  if (pageSwitchData) {
    sessionStorage.setItem("businessPageSwitchData", JSON.stringify(pageSwitchData));
  }
  return {
    path: businessPath,
    query: {
      id,
      templateId,
      openType: BusinessDataOpenType.view,
      pageType: BusinessDataPageType.fullscreen,
      businessType,
      templateName,
      tripId,
      tripType
    }
  };
};

/**
 * 天眼查返回业务建模的路由, 请使用replace方法替换当前路由
 * @param id
 * @param business
 * @returns {{path: string, query: *}}
 */
const skyEyeReturnRoute = function skyEyeReturnRoute({ id, business, path = "/businessModel/detail" }) {
  let businessQuery = JSON.parse(decodeURI(business));
  businessQuery = Object.assign(businessQuery, {
    id
  });
  return {
    path,
    query: businessQuery
  };
};

/**
 * 把当前路由转换成点编辑之后的路由..
 * @param route
 * @returns {{path: *, query: *}}
 */
const convertCurrentDetailRouteToEdit = function convertCurrentDetailRouteToEdit(route, version = undefined) {
  const copiedRoute = cloneDeep(route.query);
  // 编辑
  copiedRoute.openType = BusinessDataOpenType.edit;
  const query = {
    ...copiedRoute,
    version
  };
  return {
    path: route.path,
    query
  };
};

/**
 * 把当前路由转换成点复制之后的路由
 * @param route
 * @returns {{path: *, query: *}}
 */
const convertCurrentDetailRouteToCopy = function convertCurrentDetailRouteToCopy(route) {
  const copiedRoute = cloneDeep(route.query);
  // 复制等于新增
  copiedRoute.openType = BusinessDataOpenType.add;
  // 赋值复制id 删除数据id
  copiedRoute.copyId = copiedRoute.id;
  delete copiedRoute.id;
  const query = { ...copiedRoute };
  return {
    path: route.path,
    query
  };
};

export {
  newEntranceRoute, // 新增入口
  messageNewButtonEntranceRoute, // 消息中心点击按钮新增业务建模
  resourceNewButtonEntranceRoute, // 资源新增
  businessListEntranceRoute, // 业务建模列表入口
  businessDetailPageSwitchRoute, // 业务建模详情左右切换
  readOnlyListEntranceRoute, // 只读模式入口,比如报表
  otherEntranceRoute, // 其它入口,比如待办进入
  newTabViewBusinessData, // 新开页查看业务建模数据,比如关联数据查看
  newTabAddBusinessData, // 新开页新增数据
  newTabReadonlyBusinessData, // 新开页只读数据
  skyEyeReturnRoute, // 天眼查回来的入口
  convertCurrentDetailRouteToEdit, // 点击编辑之后的路由转换
  convertCurrentDetailRouteToCopy, // 点击复制时候的路由转换
  newBusinessDialogParams, // 新增业务建模弹窗所需参数
  newBusinessTaskParams, // 新增业务建模任务所需参数
  viewBusinessDialogParams, // 查看业务建模弹窗所需参数
  readonlyBusinessDialogParams// 只读业务建模弹窗所需参数
};
